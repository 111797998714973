import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { NyckelonlineApiService } from '@app/nyckelonline-api.service';
import { SelectedItems, CompanyRole, CustomerCompany } from './../datamodel/security';
import { SelectedItemsServiceService } from './../selected-items-service.service';
import { AuthService } from '../auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  searchString = '';
  items: CustomerCompany[]= [];
  selectedItem: CustomerCompany;
  showEdit=false;

  constructor(  
    private selectedItems: SelectedItemsServiceService,
    public auth: AuthService,
    public snackBar: MatSnackBar,
    private nyckelonlineApi: NyckelonlineApiService) {
      console.log('ngInit');
      this.selectedItems.displayTitle = true;
      
      this.selectedItems.useFont=false;
      this.selectedItems.useSvg=true;
      this.selectedItems.pageTitle = 'anms.companies'
      this.selectedItems.pageIcon = 'company';
      this.selectedItems.initialized = false;
    }

  ngOnInit() {
    this.getItems();
  }

  onSetSelected(item: CustomerCompany) {
    this.selectedItem=item;
    this.getItem(item._id);
  }

  onSearch() {
    this.getItems();
  }

  itemTracker(index, item) {
    return item.id;
  }

  getItems() {
    this.nyckelonlineApi.getCompanies().subscribe(data=> {
      this.items=data;
      this.items.sort((a,b) => a.name.localeCompare(b.name));
      this.selectedItems.initialized=true;
      
      if(this.items.length>0) {
          this.getItem(this.selectedItem._id);
        }
    })
  }

  getItem(id: string) {
    this.nyckelonlineApi.getCustomerCompany(id).subscribe(data=> {
     this.selectedItem=data;
    });
  }

}
