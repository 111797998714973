import { Guid } from 'guid-typescript';
import { HttpErrorResponse } from '@angular/common/http';
import { SystemJsNgModuleLoader, Version } from '@angular/core';
import { User } from './security';

export class LastProfil {
    public  dygnNr : number;
    public  varme: number;
    public  tappvatten: number;
    public  anga : number;
}

export class DagNattVarde
{
    public  monthNumber: number;
    public  varmedag: number;
    public  varmenatt: number;
    public  tappvattendag: number;
    public  tappvattennatt: number;
    public  angadag: number;
    public  anganatt: number;
}

export class MMELastkurvaTimmar {
    public versionIndata: IndataVersion;
    public versionSchema: VersionInfo;
    public  _id: string;
    public namn : string;
    public tags: Tag[];
    public guid: string;
    public kommentar: string;
}

export class IndataPage {
    public num: number;
    public icon: string;
    public title: string;
}
export class MMELastkurva {
    public versionIndata: IndataVersion;
    public versionSchema: VersionInfo;
    public  _id: string;
    public namn : string;
    public tags: Tag[];
    public guid: string;
    public kommentar: string;
    public lastprofil: LastProfil[];
    public dagnattvarden: DagNattVarde[];
}
export class MMESystem
{

    public versionSchema: VersionInfo;
    public Lastkurva: MMELastkurva;
    public LastkurvaTimmar: MMELastkurvaTimmar;
    public  x : number;
    public  y : number;
    public _id: string;
    public  namn : string;
    public  foretag : string;
    public  kommentar : string;
    public  lastdiagramNamn: string; 
    public anga: Boolean;
    public  ackumulatorKopplad: Boolean;
    public  tim_IBAckMWh : number;
    public  ackumulator24 : string;
    public natavgift : MMEPeriodComponent;
    public lastoberoendeEgenforbrukning : MMEPeriodComponent;
    public distributionsforlust: MMEPeriodComponent;

}

export class MMEEfterfragan
{
    public _id:string;
    public VersionSchema: VersionInfo;
    public scenarioId: string;
    public systemId: string;
    public energibehov_FV_h1: number;
    public  energibehov_anga_h1 : number;
    public  utnyttjningstid_FV_h1: number;
    public  utnyttjningstid_anga_h1 : number;
    public  besparing_varme_h1 : number;
    public  besparing_anga_h1 : number;
    public  besparing_tappvatten_h1 : number;
    public  energimangd_h1 : number;
    public  energibehov_FV_h2 : number;
    public  energibehov_anga_h2 : number;
    public  utnyttjningstid_FV_h2 : number;
    public  Utnyttjningstid_anga_h2 : number;
}


export class  MMEDefaultValueName
{
    public name: string;
    public groupname: string;
    public value: string;
}

export class MMEDefaultValue {
    public _id: string;
    public attributeName: string;
    public numberofvalues: number;
    public maxNumberOfFreePeriods: number;
    public useSeparators: boolean;
    public dataType: string;
    public isConfigurable: boolean;
    public allowPeriodTypeChange: boolean;
    public allowPeriodTypeHour: boolean;
    public isModified: boolean;
    public valueNames: MMEDefaultValueName[];
    

}

export class Tag 
{
   public text: string;
}

export class VersionInfo
{
        version:number;
}
export class IndataVersion
{
    public version:number;
    public parentVersion:number;
    public tags: Tag[];
    public guid: string;
    public dateCreated: Date;
    public dateUpdated: Date;
}

export class MMEBransleRedovisning 
{
    public  _id: string;
    public  namn: string;
    public  bransleId1 : MMEBranlse;
    public  bransleId2 : MMEBranlse;
    public  andelBransle1:number;

}

export class MMEBranslekontraktRad {

}

export class MMEPeriodComponent {

}

export class MMEBranslekontrakt
{
    public versionSchema : VersionInfo;  
    public _id: string;
    public bransleRef: MMEBranlse;
    public effektbegränsning : MMEPeriodComponent;
    public aktiv: boolean;
    public rader : MMEBranslekontraktRad[];
}

export class MMEScenario {
    public _id: string;
    public versionSchema: VersionInfo;
    public name: string;
    public calendarYear: Number;
    public elcertAndel: Number;
    public active: boolean;
    public simOrdNum: Number;
    public kvskatt: MMEPeriodComponent;
    public comment: string;
}

export class MMEIndatauppsattning {
    public _id: string;
    public versionSchema: VersionInfo;
    public indataVersion: IndataVersion;
    public _createdBy: User;
    public _createdDate: Date;
    public tags: Tag[];
    
    public name: string;
    public comment: string;
    
    public bransleRedovisningLista: MMEBransleRedovisning[];
    public branslekontrakt: MMEBranslekontrakt[];
    public scenarios: MMEScenario[];
    public systems: System[];
    public anlaggningar: MMEAnlaggning[];
    public efterfragan: MMEEfterfragan[];
}

export class Ackumulator {
    public _id: string;
}

export class System {
    public _id: string;
    public name: string;
    public ackumulator: Ackumulator;
}

export class MMEAnlaggning {
    public _id: string;
    
    public name: string;
    public system: System;
    public fuel1: PlantFuel;
    public fuel2: PlantFuel;
}

export class MMEBranlse {
    public _id: string;
    public name: string;
}

export class MMEEmission {
    public _id: string;
    public name: string;
}

export class PlantFuel {
    public fuel: MMEBranlse;
    public emission: MMEEmission;
    public emissionCoefficient: number;
}