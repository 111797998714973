
import { shareReplay, map, tap, filter, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import {  HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { environment } from 'environments/environment';
import { MMEIndatauppsattning, MMEDefaultValue } from './datamodel/indata';
import { User, CustomerCompany, CompanyRole } from './datamodel/security';

import { of } from 'rxjs'
import { share } from 'rxjs/operators';
import { pipe } from '@angular/core/src/render3/pipe';
import { AuthService } from './auth/auth.service';

const CACHE_SIZE = 1;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json'})
};

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class NyckelonlineApiService {

 

 
  constructor(private http: HttpClient) { }

  public addUser(companyId: string) {
    const p = new HttpParams()
    .set('companyId', companyId)

    this.http.post (API_URL + '/addUser', p);
  }
  
  public getObjectEvents(objectid: string) {

    const p = new HttpParams()
    .set('ObjectId', objectid)
    console.log('get object events...');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<boolean>(API_URL + '/getObjectEvents',  {params: p}, { headers } );
  }

  

  

  // 2018-12-06
  

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    if(error.status==401) {
      alert("An error occured: " + error.status + " " + error.message);
    }

    // return an observable with a user-facing error message
    return throwError(error.status);
      
  };


  public getCustomerCompany(companyId: string): Observable<CustomerCompany> {
    const p = new HttpParams()
    .set('companyId', companyId);
    console.log('calling getCompany');
    return this.http.get<CustomerCompany>(API_URL + '/getCompany', {params: p} );
  }


  public getDefaultValues(companyId: string, search: string): Observable<MMEDefaultValue[]> {
    const p = new HttpParams()
    .set('companyId', companyId)
    .set('search', search);
    console.log('calling getDefaultValues');
    return this.http.get<MMEDefaultValue[]>(API_URL + '/getDefaultValues', {params: p} );
  }

  public getDefaultValue(companyId: string, search: string): Observable<MMEDefaultValue> {
    const p = new HttpParams()
    .set('companyId', companyId)
    .set('search', search);
    console.log('calling getDefaultValues');
    return this.http.get<MMEDefaultValue>(API_URL + '/getDefaultValue', {params: p} );
  }

  public getUsers(companyId: string, search: string): Observable<User[]> {
    const p = new HttpParams()
    .set('companyId', companyId)
    .set('search', search);
    console.log('calling getUsers');
    return this.http.get<User[]>(API_URL + '/getUsers', {params: p} );
  }

  public getCurrentUser(): Observable<User> {
    return this.http.get<User>(API_URL + '/getCurrentUser');
  }

  public getCompanyRoles(): Observable<CompanyRole[]> {
    console.log('calling getCompanyRoles');
    return this.http.get<CompanyRole[]>(API_URL + '/getCompanyRoles');
  }

  public getCompanies(): Observable<CustomerCompany[]> {
    console.log('calling getCompanies');
    return this.http.get<CustomerCompany[]>(API_URL + '/getCompanies');
  }


}
