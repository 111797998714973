import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import {  PageSettings,  ObjectType,  ObjectStatus } from '@app/datamodel/security';
import { SelectedItemsServiceService } from '@app/selected-items-service.service';
import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel';
import { jqxTreeComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxtree';
import { jqxMenuComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxmenu';
import { NyckelonlineApiService } from '@app/nyckelonline-api.service';
import { TranslateService } from '@ngx-translate/core';
import { IndataComponent } from '@app/indata/indata.component';


@Component({
  selector: 'app-indatas',
  templateUrl: './indatas.component.html',
  styleUrls: ['./indatas.component.scss']
})
export class IndatasComponent implements OnInit {

  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('container', {read: ViewContainerRef}) viewContainerRef: ViewContainerRef;
  pageSettings: PageSettings;
 
 
  source: {};
  dataAdapter = null;
  records: any;
  selectedElement;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver, public selectedItems: SelectedItemsServiceService, 
      private translate: TranslateService,
      private nyckelonlineApi: NyckelonlineApiService) { 
      this.pageSettings=new PageSettings();
      this.pageSettings.sidnavwidth=300;
      this.pageSettings.sidenavwidthopen=300;
      this.pageSettings.sidenavopen=true;

      this.selectedItems.useFont=true;
      this.selectedItems.useSvg=false;
      this.selectedItems.displayTitle = true;
      this.selectedItems.pageTitle = 'anms.indatas';
      this.selectedItems.pageIcon = 'fa-building';

  }

  showPage(component) {
    try {
      const factory = this.componentFactoryResolver.resolveComponentFactory(component);
      this.viewContainerRef.detach();
      const ref = this.viewContainerRef.createComponent(factory);
      ref.changeDetectorRef.detectChanges();
    }
    catch(e) {
      alert(e);
    }
  }

 

  onSearch() {
   
    }

  onNavigateBack() {
     
    let nextItem = this.selectedElement.element.parentElement.parentElement;

       if (nextItem != null) {
           this.myTree.selectItem(nextItem);
           this.myTree.ensureVisible(nextItem);
       }
 }

  toggle() {
    
    this.pageSettings.toogle();
  }


  ngOnInit() {
      this.onSearch();
  }

}

