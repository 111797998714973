import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { NyckelonlineApiService } from '@app/nyckelonline-api.service';
import { SelectedItems, User, CompanyRole, CustomerCompany } from './../datamodel/security';
import { MMEDefaultValue } from './../datamodel/indata';
import { SelectedItemsServiceService } from './../selected-items-service.service';
import { AuthService } from '../auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-default-value',
  templateUrl: './default-value.component.html',
  styleUrls: ['./default-value.component.scss']
})
export class DefaultValueComponent implements OnInit {
  searchString = '';
  user: User;
  items: MMEDefaultValue[]= [];
  selectedItem: MMEDefaultValue;
  showEdit=false;

  constructor(  
    public selectedItems: SelectedItemsServiceService,
    public auth: AuthService,
    public snackBar: MatSnackBar,
    private nyckelonlineApi: NyckelonlineApiService) {
      console.log('ngInit');
      this.selectedItems.displayTitle = true;
      
      this.selectedItems.useFont=false;
      this.selectedItems.useSvg=true;
      this.selectedItems.pageTitle = 'anms.defaultValues'
      this.selectedItems.pageIcon = 'defaultValue';
      this.selectedItems.initialized = false;
      this.user=this.selectedItems.companyRole.user;
    }

  ngOnInit() {
    this.getItems();
  }

  onSetSelected(item: MMEDefaultValue) {
    this.selectedItem=item;
    this.getItem(item._id);
  }

  onSearch() {
    this.getItems();
  }

  itemTracker(index, item) {
    return item.id;
  }

  getItems() {
   
  }

  getItem(id: string) {
   
  }

}
