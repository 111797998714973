import { jqxGridComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { jqxTreeComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxtree';
import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel';

import { NgxTypeaheadModule } from 'ngx-typeahead';
import { CacheModule } from '@ngx-cache/core';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Type } from '@angular/core';
import { CoreModule } from '@app/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { CookieLawModule } from 'angular2-cookie-law';

import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';

import { ROUTES } from './app.routes';
import { AuthService } from './auth/auth.service';
import { CallbackComponent } from './callback/callback.component';

import { NotFoundComponent } from './not-found/not-found.component';
import { AdminUserComponent } from './admin-user/admin-user.component';
import { StartComponent } from './start/start.component';
import { UserConfigDialogComponent } from './user-config-dialog/user-config-dialog.component';
import { Store, StoreModule } from '@ngrx/store';
import { NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { SettingsModule } from './settings';
import { FeaturesModule } from '@app/features/features.module';
import { SharedModule } from '@app/shared';
import { ContactComponent } from './contact/contact.component';

import { BookdemoComponent } from './bookdemo/bookdemo.component';
import { MyaccountComponent } from './myaccount/myaccount.component';


import { GraphvisualizerComponent } from './graphvisualizer/graphvisualizer.component';

import { PdftestComponent } from './experiments/pdftest/pdftest.component';

import { ReportcontainerComponent } from './reports/reportcontainer/reportcontainer.component';




import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { jqxMenuComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxmenu';


import { DashboardComponent } from './dashboard/dashboard.component';


import { CompanyComponent } from './company/company.component';

import { RootuserconsoleComponent } from './rootuserconsole/rootuserconsole.component';
import { IndataComponent } from './indata/indata.component';
import { DefaultValueComponent } from './default-value/default-value.component';
import { IndatasComponent } from './indatas/indatas.component';
import { ResultsComponent } from './results/results.component';
import { SystemsComponent } from './systems/systems.component';
import { ScenariosComponent } from './scenarios/scenarios.component';
import { TesttableComponent } from './testtable/testtable.component';
import { CdkTableModule} from '@angular/cdk/table';
import { SimComponent } from './sim/sim.component';
import { IndatahomeComponent } from './indatahome/indatahome.component';
import { EmissionComponent } from './emission/emission.component';
import { DeliveryRevenueComponent } from './delivery-revenue/delivery-revenue.component';
import { FuelComponent } from './fuel/fuel.component';
import { EmissionCoeffComponent } from './emission-coeff/emission-coeff.component';
import { EleffektComponent } from './eleffekt/eleffekt.component';
import { UploadDirective } from './upload.directive';
import { UploadComponent } from './upload/upload.component';


const PRINT_BREAKPOINTS = [{
  alias: 'xs.print',
  suffix: 'XsPrint',
  mediaQuery: 'print and (max-width: 297px)',
  overlapping: false
}];

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function tokenGetter() {
  var a =  localStorage.getItem('magnemyr_access_token');
 
  return a;
}

@NgModule({
  declarations: [
    AppComponent,
    
    CallbackComponent,
    NotFoundComponent,
    AdminUserComponent,
    StartComponent,
    UserConfigDialogComponent,
    ContactComponent,
   
    BookdemoComponent,
    MyaccountComponent,
    GraphvisualizerComponent,
    jqxTreeComponent,
    jqxPanelComponent,
    jqxGridComponent,
    jqxMenuComponent,
    PdftestComponent,
    ReportcontainerComponent,
    AlertDialogComponent,
    DashboardComponent,
    CompanyComponent,
    RootuserconsoleComponent,
    IndataComponent,
    DefaultValueComponent,
    IndatasComponent,
    ResultsComponent,
    SystemsComponent,
    ScenariosComponent,
    TesttableComponent,
    SimComponent,
    IndatahomeComponent,
    EmissionComponent,
    DeliveryRevenueComponent,
    FuelComponent,
    EmissionCoeffComponent,
    EleffektComponent,
    UploadDirective,
    UploadComponent
    ],
  imports: [
    CoreModule,
    SharedModule,
    SettingsModule,
    BrowserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, {
        enableTracing: false, // TODO: enableTracing is for debug reasons only
        useHash: false}), // TODO: Find out more about html server push state... this thing adds a # in the path...
    MaterialModule,
    
    CookieLawModule,
    NgbModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['localhost:4200', 'magnemyrapi.azurewebsites.net'],
        blacklistedRoutes: ['localhost:4200/auth', 'magnemyrapi.azurewebsites.net/auth'],
      },
    }),
    CacheModule.forRoot(),
    PerfectScrollbarModule,
    NgxTypeaheadModule,
    CdkTableModule
  
    
    
  ],
  providers: [
    AuthService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    UserConfigDialogComponent, 
    AlertDialogComponent, 
    SystemsComponent, 
    ScenariosComponent,
    TesttableComponent,
    SimComponent,
    IndatahomeComponent,
    EmissionComponent,
    DeliveryRevenueComponent,
    FuelComponent,
    EmissionCoeffComponent,
    EleffektComponent
  ],
 
})
export class AppModule { }
