import { Component, OnInit } from '@angular/core';
import { SelectedItemsServiceService } from '@app/selected-items-service.service';

@Component({
  selector: 'app-rootuserconsole',
  templateUrl: './rootuserconsole.component.html',
  styleUrls: ['./rootuserconsole.component.scss']
})
export class RootuserconsoleComponent implements OnInit {

  constructor(public selectedItems: SelectedItemsServiceService) { }

  ngOnInit() {
  }

}
