
import { MatChipsModule } from '@angular/material/chips';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatToolbarModule, MatIconModule, MatSelectModule, MatListModule,
  MatDialogModule, MatCardModule, MatMenu, MatMenuModule, MatSidenavModule,
MatTooltipModule, MatChipList, MatTreeModule, MatDividerModule, MatCheckboxModule
, MatSnackBarModule
, MatInputModule, MatBadgeModule, MatExpansionModule,
MatAutocompleteModule, MatProgressSpinnerModule, MatNativeDateModule, MatDatepickerModule,
MatGridListModule, MatTableModule, MatProgressBarModule  } from '@angular/material';


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSelectModule,
    MatListModule,
    MatDialogModule,
    MatCardModule,
    MatMenuModule,
    MatSidenavModule,
    MatTooltipModule,
    MatTreeModule,
    MatDividerModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatInputModule,
    MatBadgeModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatGridListModule,
    MatTableModule,
    MatProgressBarModule
  ],
  exports: [
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSelectModule,
    MatListModule,
    MatDialogModule,
    MatMenuModule,
    MatCardModule,
    MatSidenavModule,
    MatTooltipModule,
    MatChipsModule,
    MatTreeModule,
    MatDividerModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatInputModule,
    MatBadgeModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatGridListModule,
    MatTableModule,
    MatProgressBarModule
  ],
  declarations: [

  ]
})
export class MaterialModule { }
