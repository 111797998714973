import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ROUTE_ANIMATIONS_ELEMENTS } from '../core';
import {  CompanyRole, SelectedItems, User } from '../datamodel/security';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import {
  Country,
  UsernameValidator,
  PasswordValidator,
  ParentErrorStateMatcher,
  PhoneValidator
} from '../validators';


@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.scss']
})
export class AdminUserComponent implements OnInit, OnDestroy {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public users: User[] = [];
  public userDetailsForm: FormGroup;
  country_phone_group: FormGroup;
  parentErrorStateMatcher = new ParentErrorStateMatcher();



  countries = [
    new Country('SE', 'Sweden'),
    new Country('US', 'United States'),
    new Country('AR', 'Argentina')
  ];

  initialized;

  public user: User;

  validation_messages = {
    'firstName': [
      { type: 'required', message: 'amns.messages.firstName.required' }
    ],
    'lastName': [
      { type: 'required', message: 'amns.messages.lastName.required' }
    ],
    'phone': [
      { type: 'required', message: 'amns.messages.phone.required' },
      { type: 'validCountryPhone', message: 'amns.messages.phone.incorrectFormatForCountry' }
    ],
    'email': [
      { type: 'required', message: 'amns.messages.email.required' },
      { type: 'pattern', message: 'amns.messages.email.notValid' }
    ]
  };

  constructor(
      public store: Store<any>,
      public snackBar: MatSnackBar,
      private fb: FormBuilder
    ) {



      }
      createForms() {
        // country & phone validation

        let country = new FormControl(this.countries[0], Validators.required);

        let phone = new FormControl(this.user.phone, {
          validators: Validators.compose([
            PhoneValidator.validCountryPhone(country)
          ])
        });

        this.country_phone_group = new FormGroup({
          country: country,
          phone: phone
        });

        this.userDetailsForm = this.fb.group({
          firstName: [
            this.user.firstName,
            Validators.compose([
              UsernameValidator.validUsername,
              Validators.maxLength(50),
              Validators.required
            ])
          ],
          lastName: [
            this.user.lastName,
            Validators.compose([
              UsernameValidator.validUsername,
              Validators.maxLength(50),
              Validators.required
            ])
          ],
          email: new FormControl(this.user.email, Validators.compose([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
          ])),
          country_phone: this.country_phone_group
        });
      }
  public setSelected(item: User) {
    this.user = item;
    this.createForms();
  }

  onSubmit(value) {
    alert('SUCCESS!! :-)\n\n' + JSON.stringify(value))
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }



  onToggleUser(user: User) {
    const newStatus = user.isProfuAdmin;
  }

  private showNotification(message: string, action?: string) {
    return this.snackBar.open(message, action, {
      duration: 2500,
      panelClass: 'todos-notification-overlay'
    });
  }

}
