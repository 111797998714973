import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {  HttpClient, HttpRequest , HttpHeaders, HttpParams, HttpErrorResponse, HttpEventType  } from '@angular/common/http';
import { Uploader } from '../entities/uploader';
import { UploadQueue } from '../entities/uploadqueue';
import { environment } from 'environments/environment';
import { SelectedItemsServiceService } from '@app/selected-items-service.service';
import { PageSettings } from '@app/datamodel/security';


const API_URL = environment.apiUrl;

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})


export class UploadComponent implements OnInit { 

  color = 'primary';
  mode = 'determinate';
  value = 50;
  bufferValue = 75;
  pageSettings: PageSettings;

  public message: string;
  public uploader: Uploader = new Uploader();

  constructor(private http: HttpClient, 
    private translate: TranslateService, public selectedItems: SelectedItemsServiceService) {
    this.message = '';    

    this.selectedItems.useFont=true;
    this.selectedItems.useSvg=false;
    this.selectedItems.displayTitle = true;
    this.selectedItems.pageTitle = 'anms.upload';
    this.selectedItems.pageIcon = 'fa-building';

  }

    //getter : get overall progress
    get progress(): number {
    let psum = 0;

    for (let entry of this.uploader.queue) {
      psum += entry.progress;
    }

    if (psum == 0)
      return 0;

    return Math.round(psum / this.uploader.queue.length);
    };


  ngOnInit() {
    
  }

  onFilesChange(fileList: Array<File>) {
    for (let file of fileList) {
      this.uploader.queue.push(new UploadQueue(file));
    };  
  }

  onFileInvalids(fileList: Array<File>) {
    //TODO handle invalid files here
  }
  
  onSelectChange(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;

    for(var i=0; i<=files.length-1; i++ ) {
      let file = files[i];
      if (file) {
        this.uploader.queue.push(new UploadQueue(file));
      }
    }
    
    console.log('Total Count:' + this.uploader.queue.length);      
    
  }

  // upload 
	upload(id) {

    if (id == null)
			return;

      let selectedFile = this.uploader.queue.find(s => s.id == id);
      if (selectedFile) {
        const formData = new FormData();
        formData.append(selectedFile.file.name, selectedFile.file);

        const uploadReq = new HttpRequest('POST', API_URL +'/uploadFile', formData, {
          reportProgress: true,
        });

        this.http.request(uploadReq).subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {           
            selectedFile.progress = Math.round(100 * event.loaded / event.total);
          }
          else if (event.type === HttpEventType.Response)
            selectedFile.message = event.body.toString();
        });
      }
  }
  //upload all selected files to server
  uploadAll() {
    //find the remaning files to upload
    let remainingFiles = this.uploader.queue.filter(s => !s.isSuccess);
    for (let item of remainingFiles) {
      if(item.isCancel==false) {
        this.upload(item.id);
      }
    }
  }

  remove(item){
    var idx = this.uploader.queue.findIndex(x=>x.id==item.id);
    this.uploader.queue.splice(idx,1);
  }
  removeAll() {
    for(var i=0;i<=this.uploader.queue.length-1;i++) {
      this.uploader.queue.splice(i);
    }
  }

  // cancel all 
  cancelAll() {
    let remainingFiles = this.uploader.queue.filter(s => !s.isSuccess);
    for (let item of remainingFiles) {
      item.isCancel=true;
    }
  }
}