import { AuthService } from './auth/auth.service';
import { Injectable } from '@angular/core';
import { CompanyRole, ObjectType, SelectedItems } from './datamodel/security';
import { NyckelonlineApiService } from './nyckelonline-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of as observableOf } from 'rxjs';
import { MMEIndatauppsattning } from './datamodel/indata';

@Injectable({
  providedIn: 'root'
})
export class SelectedItemsServiceService {

  public items: SelectedItems = null;
  public showNavMenu=true;
  public companyRole: CompanyRole;
  
  public indatauppsattning: MMEIndatauppsattning;
  public tree;

  public haveSelectedCompany: Boolean = false;
  

  public displayTitle = false;
  public pageTitle = '';
  public pageIcon = 'fa-shopping-cart';
  public useFont = true;
  public useSvg = false;
  public initialized=false;

  public windowWidth=0;
  public windowHeight=0;
  public showDebugConsole=false;

  public objectTypes: ObjectType[] = [
    {_id:"1", idLevel2:1, level1:'site', level2:'Plats', icon:'site'},
    {_id:"2", idLevel2:2, level1:'door', level2:'Entredörr', icon:'door'},
    {_id:"3", idLevel2:2, level1:'door', level2:'Balkongdörr', icon:'door'},
    {_id:"4", idLevel2:2, level1:'door', level2:'Låsbart utrymme', icon:'door'},
    {_id:"7", idLevel2:4, level1:'floor', level2:'anms.objecttype.floor', icon:'floor'},
    {_id:"10", idLevel2:5, level1: 'lock', level2:'anms.objecttype.cylinder', icon:'cylinder'},
    {_id:"19", idLevel2:5, level1: 'lock', level2:'Kortläsare', icon:'cardreader'},
    {_id:"20", idLevel2:5, level1: 'lock', level2:'anms.objecttype.smartlock', icon:'smartlock'}
  ]
  
  constructor(private _nyckelonlineApi: NyckelonlineApiService) { }


  public toggleNavMenu() {
    this.showNavMenu=!this.showNavMenu;
  }

  getSelectedCompanyRole(): CompanyRole {
    const a: string = localStorage.getItem('selectedCompany');
    // even if a can not be parsed to a valid Company instance no error will be thrown. Why?
    const comp: CompanyRole = JSON.parse(a);

      if (comp === undefined || comp === null || comp.name === undefined ) {
        const emptyCompany = new CompanyRole();
        emptyCompany._id = 'Test';
        emptyCompany.name = 'Test';
        emptyCompany.role.name = 'Test';
        return emptyCompany;
    } else {
      const d: CompanyRole = new CompanyRole();
      this.haveSelectedCompany = true;
      return JSON.parse(a);
    }
  }

  initSelectedCompany() {
    // this can evaluate to anything, no error will be thrown. Why?
    this.companyRole = this.getSelectedCompanyRole();
    return Promise.resolve(this.companyRole);
  }


  setSelectedCompany(theSelectedCompany: CompanyRole) {
    return new Promise (resolve => {
      if (theSelectedCompany !== undefined) {
        // todo: find out why theSelectedCompany is an array (some times)
        const a = JSON.stringify(theSelectedCompany);
        this.companyRole = theSelectedCompany;
        localStorage.setItem('selectedCompany', a);
       // localStorage.setItem('selectedLockschema', this.company.lockSchemas[0]);
        this.haveSelectedCompany = true;
        this.items = null;
       

        console.log('setting selected company:' + this.companyRole._id);
       
        resolve();
      } else {
        resolve();
      }
    });
  }

 

  get selectedItems(): SelectedItems {
    if (this.items === null) {
      const a: string = localStorage.getItem ( this.companyRole._id);

      if (a !== null) {
        this.items = JSON.parse( a );
      } else {
        this.items = new SelectedItems();
       
      }
    }
    return this.items;
  }

 

  
}
