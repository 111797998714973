import { CompanyRole } from './../datamodel/security';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  hasRole(roles: string[]) {
    if ( roles === undefined) {
      return true;
    } else {
      if ( roles.some(x =>  x === this.authService.Role.role.name)) {
        return true;
      } else {
        return false;
      }
    }
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


    if (!this.authService.isAuthenticated()) {


      localStorage.setItem('url', state.url);
      this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
      return false;
    }

    let c = true;

    if ( next.data !== undefined) {
      if (next.data['allowRoles'] !== null ) {
        c = this.hasRole(next.data['allowRoles']);
      } else {
        console.log('this page requires no particular role');
      }
    }

    if ( c === true) {
      localStorage.setItem('url', state.url);
    }

    return c;
  }
}
