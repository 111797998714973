import { tokenGetter } from './../app.module';
import { EventEmitter, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { ChatMessage } from '@app/datamodel/hubmodel';
import { environment } from 'environments/environment';


const API_URL = environment.orderHubUrl;

@Injectable({
  providedIn: 'root'
})
export class SignalROrderService {

  messageReceived = new EventEmitter<ChatMessage>();
  contractSigned = new EventEmitter<ChatMessage>();

  connectionEstablished = new EventEmitter<Boolean>();
  private connectionIsEstablished = false;
  private _hubConnection: HubConnection;
  constructor() {

  }

  public start(token: string) {
    this.createConnection(token);
    this.registerOnServerEvents();
    this.startConnection();
  }

  sendChatMessage(message: ChatMessage) {
    this._hubConnection.invoke('SendMessage', message);
  }
  private createConnection(token: string) {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(API_URL + '/orderhub' , { accessTokenFactory: () => token })
      .configureLogging(signalR.LogLevel.Information)
      .build();
  }

  public stop() {
    if ( this._hubConnection !== undefined) {
      this._hubConnection.stop();
    }
  }

  private startConnection(): void {

      this._hubConnection
        .start()
        .then(() => {
          this.connectionIsEstablished = true;
          console.log('Hub connection started');
          this.connectionEstablished.emit(true);
        })
        .catch(err => {
          console.log('Error while establishing connection, retrying...');
          // setTimeout(this.startConnection(), 5000);
        });
  }

  private registerOnServerEvents(): void {
    this._hubConnection.on('ReceiveMessage', (data: any) => {
      this.messageReceived.emit(data);
    });

    this._hubConnection.on('ContractSigned', (data: any) => {
      this.contractSigned.emit(data);
    });
  }
}
