import { Component, OnInit, ViewChild } from '@angular/core';
import { ROUTE_ANIMATIONS_ELEMENTS } from '../core';
import { TranslateService } from '@ngx-translate/core';
import {  PageSettings,  ObjectType,  ObjectStatus } from '@app/datamodel/security';
import { SelectedItemsServiceService } from '@app/selected-items-service.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  pageSettings: PageSettings;
  constructor(public selectedItems: SelectedItemsServiceService) { }

  ngOnInit() {
    this.pageSettings=new PageSettings();
      this.pageSettings.sidnavwidth=300;
      this.pageSettings.sidenavwidthopen=300;
      this.pageSettings.sidenavopen=true;

      this.selectedItems.useFont=true;
      this.selectedItems.useSvg=false;
      this.selectedItems.displayTitle = true;
      this.selectedItems.pageTitle = 'anms.contact';
      this.selectedItems.pageIcon = 'fa-building';
  }

}
