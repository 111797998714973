

import { SelectedItemsServiceService } from '../selected-items-service.service';
import { CompanyRole,  SelectedItems, User } from '../datamodel/security';
import { Injectable, ComponentFactory, enableProdMode } from '@angular/core';
import { AUTH_CONFIG, AUTH_CONFIG_LOCAL } from './auth0-variables';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { NyckelonlineApiService } from '../nyckelonline-api.service';
import { environment } from '@env/environment';
import { SignalROrderService } from '../services/signalrorder.service';

(window as any).global = window;

@Injectable()
export class AuthService {



  auth0 = new auth0.WebAuth({
    clientID: AUTH_CONFIG_LOCAL.clientID,
    domain: AUTH_CONFIG_LOCAL.domain,
    responseType: 'token id_token',
    audience: `http://localhost:4200`,
    redirectUri: environment.auth.callbackURL,
    scope: 'openid profile email read:messages',
    logout: {
      returnTo:'http://localhost:3000/start',
      clientID: AUTH_CONFIG_LOCAL.clientID
    }
    
  });

  

  public companies: CompanyRole[]= [];
  public user: User = new User;



  constructor(public router: Router,
    private _nyckelonlineApi: NyckelonlineApiService,
    private _selectedSrv: SelectedItemsServiceService,
    private signalRSerivice: SignalROrderService) {

      _selectedSrv.initSelectedCompany().then(z => {
        this.initFlow();
      })
    }

  get Role(): CompanyRole {
    return this._selectedSrv.companyRole;
  }

  public hasRole(listOfRoles: string[]) {
    if ( this.isAuthenticated()) {
      if (listOfRoles.includes(this.Role.role.name)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  initFlow() {
    return new Promise (resolve => {

      if ( this.isAuthenticated) {
        this._nyckelonlineApi.getCompanyRoles().subscribe(

        data => {
          console.log('getCompanyRoles success');

          this.companies = data;
          let c = this.companies.find(x => x._id === this._selectedSrv.companyRole._id);
          if (c === undefined || c === null || this.companies.length === 1) {
            c = this.companies[0];
          }
          // todo: checj if c equals undefined or null... then we need to show the
          // userConfigDialog where the user can select a company to work with.
          // However, if this.companes only contains one single company then we will not need to show
          // the dialog. Just select the first and only company as the selected.
          this._selectedSrv.setSelectedCompany(c).then(x => {
            this._nyckelonlineApi.getCurrentUser().subscribe(
              s2 => {
                console.log('getCurrentUser success');
                this.user = s2;
                
                console.log('starting signalR hub connections...');
                this.signalRSerivice.start(this.token);
                resolve();
              }
            );
          this.router.navigate([this.getRedirectUrl()]);
        })});
      } else {
        resolve();
      }
    });
  }

  public getRedirectUrl(): string {
    var url='/home';

    if ( this.isAuthenticated) {
      url = localStorage.getItem('url');
      if (url === null) {
        url = '/start';
      }
    } else {
      url='/start';
    }
    return url;
  }

  public login(): void {
    this.auth0.authorize();
  }

  get token(): string {
    return localStorage.getItem('magnemyr_access_token');
  }

  get email(): string {
    return localStorage.getItem('magnemyr_access_token_email');
  }

  set email(theEmail: string) {
    localStorage.setItem('magnemyr_access_token_email', theEmail);
  }

  get picture(): string {
    return localStorage.getItem('magnemyr_access_token_picture');
  }

  public handleAuthentication(): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.getUserInfo(authResult).then(value => {
           this.initFlow();
        });
      } else if (err) {
        this.router.navigate(['/']);
        console.log(err);
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });



  }

  private _setSession(authResult): void {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('magnemyr_access_token_access_token', authResult.accessToken);
    localStorage.setItem('magnemyr_access_token_id_token', authResult.idToken);
    localStorage.setItem('magnemyr_access_token_expires_at', expiresAt);

  }

  private setSession(authResult, profile) {
    // Save authentication data and update login status subject
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('magnemyr_access_token_access_token', authResult.accessToken);
    localStorage.setItem('magnemyr_access_token_id_token', authResult.idToken);
    localStorage.setItem('magnemyr_access_token_profile', profile);
    localStorage.setItem('magnemyr_access_token_expires_at', expiresAt);
    localStorage.setItem('magnemyr_access_token_email', profile.email);
    localStorage.setItem('magnemyr_access_token_picture', profile.picture)


  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    this.signalRSerivice.stop();
    localStorage.removeItem('magnemyr_access_token_access_token');
    localStorage.removeItem('magnemyr_access_token_id_token');
    localStorage.removeItem('magnemyr_access_token_expires_at');
    localStorage.removeItem('magnemyr_access_token_email');
    localStorage.removeItem('magnemyr_access_token_picture');
    // Go back to the home route
    this.router.navigate(['/']);
  }

  public isSystemOwner(): boolean {
    return this._selectedSrv.companyRole.role.name === 'anms.role.systemowner' && this.isAuthenticated() ? true : false;
  }

  public isLocksmith(): boolean {
    return this._selectedSrv.companyRole.role.name === 'anms.role.locksmith' && this.isAuthenticated() ? true : false;
  }

  public isKeyholder(): boolean {
    return this._selectedSrv.companyRole.role.name === 'anms.role.keyholder' && this.isAuthenticated() ? true : false;
  }

  public isProfuAdmin(): boolean {
    if (this.user === undefined) {
      return false;
    } else {
      return this.user.isProfuAdmin && this.isAuthenticated() ? true : false;
    }
  }

  public isSubscrptionAdmin(): boolean {
    if (this.user === undefined) {
      return false;
    } else {
      return this.isSystemOwner();
    }
  }

  public isAuthAdmin(): boolean {
    if ( this.user === undefined) {
      return false;
    } else if (this.isProfuAdmin()) {
        return true;
    } else {
       return (this.user.isProfuAdmin && this.isAuthenticated()) ? true : false;
    }
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('magnemyr_access_token_expires_at') || '{}');
    return new Date().getTime() < expiresAt;
  }

  getUserInfo(authResult) {
    // Use access token to retrieve user's profile and set session
    return new Promise (resolve => {
      this.auth0.client.userInfo(authResult.accessToken, (err, profile) => {

        if (profile) {
          this.setSession(authResult, profile);
          this.email = profile.email;
          resolve(authResult);
        }
      });
    });
  }
}
