
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule, MatToolbarModule, MatIconModule, MatSelectModule, MatListModule,
  MatDialogModule, MatCardModule, MatMenu, MatMenuModule, MatSidenavModule,
MatTooltipModule, MatChipList, MatTreeModule, MatDividerModule, MatCheckboxModule
, MatSnackBarModule
, MatInputModule, MatBadgeModule, MatExpansionModule,
MatAutocompleteModule, MatProgressSpinnerModule  } from '@angular/material';

import { BigInputComponent } from './big-input/big-input.component';
import { BigInputActionComponent } from './big-input/big-input-action.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,

    MatButtonModule,
    MatToolbarModule,
    MatSelectModule,
    MatTabsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatCardModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatAutocompleteModule
  ],
    declarations: [
      BigInputComponent, 
      BigInputActionComponent,
    ],

  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatButtonModule,
    MatMenuModule,
    MatTabsModule,
    MatChipsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatCardModule,
    MatSidenavModule,
    MatListModule,
    MatSelectModule,
    MatToolbarModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    BigInputComponent,
    BigInputActionComponent
   

  ]
})
export class SharedModule {}
