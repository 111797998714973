declare var require: any;
const packageJson = require('../../package.json');

export const environment = {
  production: false,
  envName: 'DEV',
  test: false,
  appName: 'magnemyr.com',
  i18nPrefix: '',
       apiUrl: 'http://localhost:4200/api',
       //apiUrl: 'https://magnemyrapi.azurewebsites.net/api',
     orderHubUrl: 'http://localhost:4200',
     //orderHubUrl: 'https://magnemyrapi.azurewebsites.net',
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies['@angular/core'],
    ngrx: packageJson.dependencies['@ngrx/store'],
    material: packageJson.dependencies['@angular/material'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    ngxtranslate: packageJson.dependencies['@ngx-translate/core'],
    fontAwesome:
      packageJson.dependencies['@fortawesome/fontawesome-free-webfonts'],
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript']
  },
  auth: {
    
    // callbackURL: 'https://magnemyr.com/callback'
    callbackURL: 'http://localhost:3000/callback'
  }
};
