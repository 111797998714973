import { Component, OnInit, Output, EventEmitter, Injectable } from '@angular/core';
import {  PageSettings,  ObjectType,  ObjectStatus, SelectedItems } from '@app/datamodel/security';
import {  MMEScenario,  MMEIndatauppsattning,  MMEPeriodComponent } from '@app/datamodel/indata';
import { SelectedItemsServiceService } from '@app/selected-items-service.service';
import { TranslateService } from '@ngx-translate/core';
import { NyckelonlineApiService } from '@app/nyckelonline-api.service';
import {CdkTableModule} from '@angular/cdk/table';
import {DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable} from 'rxjs';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { TableDataSource, ValidatorService } from 'angular4-material-table';
import { IndataValidatorService } from '././indataValidator'


@Component({
  selector: 'app-indatahome',
  templateUrl: './indatahome.component.html',
  styleUrls: ['./indatahome.component.scss'],
  providers: [
    {provide: ValidatorService, useClass: IndataValidatorService }
  ]
})
export class IndatahomeComponent implements OnInit {
  @Output() scenarioListChange = new EventEmitter<MMEScenario[]>();
  
  displayedColumns: string[] = ['name', 'calendarYear','comment','actionsColumn'];
  dataSource: TableDataSource<MMEScenario>;


  pageSettings: PageSettings;
  items: MMEScenario[];
  item: MMEScenario;


  
  
  constructor(
    private scenarioValidator: ValidatorService,
    public selectedItems: SelectedItemsServiceService, 
    private translate: TranslateService,
    private nyckelonlineApi: NyckelonlineApiService) { }

  ngOnInit() {

    
    this.pageSettings=new PageSettings();
    this.pageSettings.sidnavwidth=300;
    this.pageSettings.sidenavwidthopen=300;
    this.pageSettings.sidenavopen=true;

    this.items=this.selectedItems.indatauppsattning.scenarios;
    this.item  = this.items[0];

    this.dataSource = new TableDataSource<any>(this.selectedItems.indatauppsattning.scenarios, MMEScenario, this.scenarioValidator);
    this.dataSource.datasourceSubject.subscribe(scenarioList => this.scenarioListChange.emit(scenarioList));
    this.dataSource.datasourceSubject.subscribe(scenarioList => this.onChange(scenarioList));
    this.pageSettings.initialized=true;
  }

  onChange(scenarioList: MMEScenario[]) {
    this.selectedItems.indatauppsattning.scenarios=scenarioList;
  }

  onSetSelectedItem(item: MMEScenario) {
    this.item=item;
  }

}
