import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import {  PageSettings,  ObjectType,  ObjectStatus } from '@app/datamodel/security';
import {  IndataPage, MMEIndatauppsattning, Tag, MMEScenario } from '@app/datamodel/indata';
import { SelectedItemsServiceService } from '@app/selected-items-service.service';
import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel';
import { jqxTreeComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxtree';
import { jqxMenuComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxmenu';
import { NyckelonlineApiService } from '@app/nyckelonline-api.service';
import { TranslateService } from '@ngx-translate/core';
import { SystemsComponent } from '@app/systems/systems.component';
import { ScenariosComponent } from '@app/scenarios/scenarios.component';
import { TesttableComponent } from '@app/testtable/testtable.component';
import { SimComponent } from '@app/sim/sim.component';
import { IndatahomeComponent } from '@app/indatahome/indatahome.component';
import { EmissionComponent } from '@app/emission/emission.component';
import { DeliveryRevenueComponent } from '@app/delivery-revenue/delivery-revenue.component';
import { FuelComponent } from '@app/fuel/fuel.component';
import { EmissionCoeffComponent } from '@app/emission-coeff/emission-coeff.component';
import { EleffektComponent } from '@app/eleffekt/eleffekt.component';



@Component({
  selector: 'app-indata',
  templateUrl: './indata.component.html',
  styleUrls: ['./indata.component.scss']
})
export class IndataComponent implements OnInit {

  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('container', {read: ViewContainerRef}) viewContainerRef: ViewContainerRef;
  pageSettings: PageSettings;
 
  page: IndataPage;
  pages: IndataPage[];
  source: {};
  dataAdapter = null;
  records: any;
  selectedElement;
  isExpanded=false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver, public selectedItems: SelectedItemsServiceService, 
      private translate: TranslateService,
      private nyckelonlineApi: NyckelonlineApiService) { 
      this.pageSettings=new PageSettings();
      this.pageSettings.sidnavwidth=300;
      this.pageSettings.sidenavwidthopen=300;
      this.pageSettings.sidenavopen=true;

      this.selectedItems.useFont=true;
      this.selectedItems.useSvg=false;
      this.selectedItems.displayTitle = true;
      this.selectedItems.pageTitle = 'anms.indata';
      this.selectedItems.pageIcon = 'fa-building';

  }

  onSetSelectedMenuItem(page: IndataPage) {
    this.page=page;
    switch(page.num) {
      case 0:
        this.showPage(IndatahomeComponent);
        break;
      case 1:
        this.showPage(ScenariosComponent);
        break;
      case 2:
        this.showPage(SystemsComponent);
        break;
      case 100:
        this.showPage(FuelComponent);
        break;
      case 200:
        this.showPage(DeliveryRevenueComponent);
        break;
      case 400:
        this.showPage(EleffektComponent);
        break;
      case 800:
        this.showPage(EmissionComponent);
        break;
      case 810:
        this.showPage(EmissionCoeffComponent);
        break;
      case 900:
        this.showPage(SimComponent);
        break;
      case 999:
        this.showPage(TesttableComponent);
        break;
    }
   
  }

  showPage(component) {
    try {
      const factory = this.componentFactoryResolver.resolveComponentFactory(component);
      this.viewContainerRef.detach();
      const ref = this.viewContainerRef.createComponent(factory);
      ref.changeDetectorRef.detectChanges();
    }
    catch(e) {
      alert(e);
    }
  }

  showNextPage() {

  }

  showPreviousPage() {

  }

  onSearch() {


    var item = new IndataPage();
    item.num=0;
    item.icon="home";
    item.title="anms.indatauppsattning";
    this.pages=[];
    this.pages.push(item);

    var item = new IndataPage();
    item.num=1;
    item.icon="calendar";
    item.title="anms.scenarios";
    
    this.pages.push(item);

    item=new IndataPage();
    item.num=2;
    item.icon="systems";
    item.title="anms.systemAndStructrue";
    this.pages.push(item);

    item=new IndataPage();
    item.num=200;
    item.icon="delivery";
    item.title="anms.deliveryRevenue";
    this.pages.push(item);
    

    item=new IndataPage();
    item.num=3;
    item.icon="plant";
    item.title="anms.plantsTech";
    this.pages.push(item);

    item=new IndataPage();
    item.num=3;
    item.icon="money";
    item.title="anms.plantsEconomy";
    this.pages.push(item);


    item=new IndataPage();
    item.num=100;
    item.icon="fuel";
    item.title="anms.fuels";
    this.pages.push(item);

    item=new IndataPage();
    item.num=4;
    item.icon="energytax";
    item.title="anms.energyTax";
    this.pages.push(item);

    item=new IndataPage();
    item.num=4;
    item.icon="carbondioxidetax";
    item.title="anms.carbonDioxidTax";
    this.pages.push(item);

    

    item=new IndataPage();
    item.num=400;
    item.icon="electricity";
    item.title="anms.eleffekt";
    this.pages.push(item);

    item=new IndataPage();
    item.num=5;
    item.icon="certificate";
    item.title="anms.elcertifikat";
    this.pages.push(item);

    item=new IndataPage();
    item.num=80;
    item.icon="net";
    item.title="anms.elnatsavgifter";
    this.pages.push(item);

    item=new IndataPage();
    item.num=800;
    item.icon="emission";
    item.title="anms.emissions";
    this.pages.push(item);

    item=new IndataPage();
    item.num=810;
    item.icon="coefficient";
    item.title="anms.emissionCoefficient";
    this.pages.push(item);

    item=new IndataPage();
    item.num=900;
    item.icon="sim";
    item.title="anms.sim";
    this.pages.push(item);

    item=new IndataPage();
    item.num=999;
    item.icon="test";
    item.title="anms.test";
    this.pages.push(item);
  }

  onNavigateBack() {
     
    let nextItem = this.selectedElement.element.parentElement.parentElement;

       if (nextItem != null) {
           this.myTree.selectItem(nextItem);
           this.myTree.ensureVisible(nextItem);
       }
 }

  toggle() {
    
    this.pageSettings.toogle();
  }

  ngOnInit() {
      this.onSearch();
      var indata = new MMEIndatauppsattning();
      indata.name="Martin Testar";
      indata.comment="Test";
      indata._createdBy=this.selectedItems.companyRole.user;
      indata.tags=[];
      
      indata.tags.push({"text":"test"});
      indata.tags.push({"text":"martin"});

      indata.scenarios=[];
     
      var s = new MMEScenario;
      s._id='a';
      s.active=true,
      s.name="2018";
      s.calendarYear=2018;
      s.comment = "Testar att skapa ett år";
      s.simOrdNum=1;
      indata.scenarios.push(s);
      s = new MMEScenario;
      s._id='b';
      s.active=true,
      s.name="2019";
      s.calendarYear=2019;
      s.comment = "Prognos 2019";
      s.simOrdNum=2;
      indata.scenarios.push(s);

      s._id='c';
      s = new MMEScenario;
      s.active=true,
      s.name="2020";
      s.calendarYear=2020;
      s.comment = "Prognos 2020";
      s.simOrdNum=3;
      indata.scenarios.push(s);
      


      this.selectedItems.indatauppsattning=indata;
      this.onSetSelectedMenuItem(this.pages[0]);

  }

}

