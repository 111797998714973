import { CompanyRole } from './../datamodel/security';
import { SelectedItemsServiceService } from '../selected-items-service.service';

import { Component, OnInit, Inject, ViewEncapsulation  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NyckelonlineApiService } from '../nyckelonline-api.service';
import { AuthService } from '../auth/auth.service';
// import * as moment from 'moment';
import {FormControl} from '@angular/forms'

@Component({
  selector: 'app-user-config-dialog',
  templateUrl: './user-config-dialog.component.html',
  styleUrls: ['./user-config-dialog.component.css']
})
export class UserConfigDialogComponent implements OnInit {

  form: FormGroup;
  description = 'Företag';
  selectedCompany: CompanyRole;

  index: number;

  constructor(public dialogRef: MatDialogRef<UserConfigDialogComponent>,
              private fb: FormBuilder,
              public auth: AuthService,
              public selectedSrv: SelectedItemsServiceService) {

  this.selectedCompany = selectedSrv.companyRole;
  this.description = '';

  this.form = fb.group({
    selectedCompany: new FormControl([this.selectedSrv.companyRole, Validators.required]),
    /*description: ['', Validators.required],
    category: ['', Va  lidators.required],
    releasedAt: [moment(), Validators.required],*/
  });
}


  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  compareFn(c1: CompanyRole, c2: CompanyRole): boolean {
    // todo: why is c2 an array? has it someting to do with angular forms?
    // c2 is an array due to the fact it is a FormControl....
    const b: boolean = c1 && c2[0] ? c1.name === c2[0].name : c1 === c2[0];
    return b;
  }

}
