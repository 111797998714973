import { Component, OnInit } from '@angular/core';
import { SelectedItemsServiceService } from '@app/selected-items-service.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  cards = [  
    { title: 'Card 1', cols: 2, rows: 1 },  
    { title: 'Card 2', cols: 1, rows: 1 },  
    { title: 'Card 3', cols: 1, rows: 2 },  
    { title: 'Card 4', cols: 1, rows: 1 }  
  ];  

  constructor(public selectedItems: SelectedItemsServiceService) { }

  ngOnInit() {
    this.selectedItems.useFont=true;
    this.selectedItems.useSvg=false;
    this.selectedItems.displayTitle = true;
    this.selectedItems.pageTitle = 'anms.dashboard'
    this.selectedItems.pageIcon = 'fa-users';
    this.selectedItems.initialized = false;
  }

}
