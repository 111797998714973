import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emission',
  templateUrl: './emission.component.html',
  styleUrls: ['./emission.component.scss']
})
export class EmissionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
