interface AuthConfig {
  clientID: string;
  domain: string;
  redirectUri: string;
  responseType: string;
  scope:string;
}

export const AUTH_CONFIG_OLD: AuthConfig = {
  clientID: 'CpbjBJh02RbOt4stSF9TnZJVkEHwwIXe',
  domain: 'mattiasmagnemyr.eu.auth0.com',
  redirectUri: 'http://localhost:3000/callback',
  responseType: 'token id_token',
  scope:'openid'
};

export const AUTH_CONFIG: AuthConfig = {
  clientID: 'CpbjBJh02RbOt4stSF9TnZJVkEHwwIXe',
  domain: 'mattiasmagnemyr.eu.auth0.com',
  responseType: 'token id_token',
  // callbackURL: 'http://localhost:3000/callback'
  redirectUri: 'https://magnemyrapi.azurewebsites.net/callback',
  scope:'openid'
};

export const AUTH_CONFIG_LOCAL: AuthConfig = {
  clientID: 'CpbjBJh02RbOt4stSF9TnZJVkEHwwIXe',
  domain: 'mattiasmagnemyr.eu.auth0.com',
  redirectUri: 'http://localhost:3000/callback',
  responseType: 'token id_token',
  scope:'openid'
};
