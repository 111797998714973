import { SelectedItemsServiceService } from './../selected-items-service.service';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ROUTE_ANIMATIONS_ELEMENTS } from '../core';
import { jqxTreeComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxtree';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;

  constructor(public auth: AuthService, public selectedItems: SelectedItemsServiceService  ) { }
  ngOnInit() {
    this.selectedItems.displayTitle = false;
  }

}
