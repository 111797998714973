
declare var require: any;

import { CompanyRole, SelectedItems } from './datamodel/security';
import { environment } from 'environments/environment';
import { UserConfigDialogComponent } from './user-config-dialog/user-config-dialog.component';
import { Component, HostBinding, OnDestroy, OnInit, NgZone, HostListener } from '@angular/core';
import { ActivationEnd, Router, NavigationEnd } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { MatDialog, MatDialogConfig} from '@angular/material';
import { SelectedItemsServiceService } from './selected-items-service.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { TranslateService } from '@ngx-translate/core';
import browser from 'browser-detect';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SignalROrderService } from '@app/services/signalrorder.service';
import { ChatMessage } from '@app/datamodel/hubmodel';
import { DomSanitizer } from "@angular/platform-browser";

import {
  AnimationsService,
  TitleService,
  routeAnimations
} from '@app/core';

import {
  NIGHT_MODE_THEME,
  selectorSettings,
  SettingsState,
  ActionSettingsPersist,
  ActionSettingsChangeLanguage,
  ActionSettingsChangeAnimationsPageDisabled
} from './settings';


import { environment as env } from '@env/environment';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatIconRegistry } from "@angular/material/icon";
import { sideNavAnimation, sideNavContainerAnimation } from '@app/shared/sidenav.animations';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  
  animations: [routeAnimations,sideNavAnimation, sideNavContainerAnimation],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'sv-SE'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  @HostBinding('class') componentCssClass;

  isConnectedToHub: boolean;
  public count = 10;
  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  logo = require('../assets/logga.png');
  languages = ['sv', 'en'];
  navigation = [
    { link: 'indata', label: 'anms.indata', icon:'indata' },
    { link: '', label: 'divider', icon:'' },
    { link: 'indatas', label: 'anms.indatas',icon:'reports' },
    { link: 'results', label: 'anms.results',icon:'results' },   
    { link: '', label: 'divider', icon:'' },

   
   
    { link: '', label: 'divider', icon:'' },
    { link: 'company', label: 'anms.licenses', icon: 'company' },
    { link: 'defaultValues', label: 'anms.defaultValues', icon: 'defaultValue' },
  ];

  public cookieLawSeen: boolean;
  settings: SettingsState;

  // menu items no authorization required
  navigation2 = [
   
    { link: 'settings', label: 'anms.menu.settings', icon: 'settings' },
   
    { link: '', label: 'divider', icon:'' },
    { link: 'contact', label: 'anms.menu.contactus', icon: 'contact' },
  
  ];

  

  // menu items for system owners
  navigation3 = [
    { link: '', label: 'divider', icon:'' },
    { link: 'users', label: 'anms.users', icon: 'users' }, 
    { link: '', label: 'divider', icon:'' },
  ]

  navigation4 = [
    { link: 'admin', label:'anms.rootUserConsole', icon:''}
  ]

  // menu items only for magnemyr.com admins

  navigationSideMenu = [
    ...this.navigation,

  ];

  private static isIEorEdge() {
    return ['ie', 'edge'].includes(browser().name);
  }

  private static trackPageView(event: NavigationEnd) {
    // (<any>window).ga('set', 'page', event.urlAfterRedirects);
    // (<any>window).ga('send', 'pageview');
  }


  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
    }
  }

  @HostListener('window:resize', ['$event'])
  reSize(event) {
    this.selectedSrv.windowWidth = event.target.innerWidth;
    this.selectedSrv.windowHeight = event.target.innerHeight;
  }




  ngOnInit(): void {
    this.translate.setDefaultLang('en');
    this.subscribeToSettings();
    // this.subscribeToIsAuthenticated();
    this.subscribeToRouterEvents();
  }

  ngAfterViewInit() {
    this.selectedSrv.windowWidth = window.innerWidth;
    this.selectedSrv.windowHeight = window.innerHeight;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public seen(evt: any) {
    this.cookieLawSeen = evt;
    console.log('agreed to cookies');
  }

  constructor(
              public overlayContainer: OverlayContainer,
              private store: Store<any>,
              private router: Router,
              private titleService: TitleService,
              private animationService: AnimationsService,
              private translate: TranslateService,
              public auth: AuthService,
              private dialog: MatDialog,
              public selectedSrv: SelectedItemsServiceService,
              public signalrService: SignalROrderService,
              private _ngZone: NgZone,
              public snackBar: MatSnackBar,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
    auth.handleAuthentication();
    this.subscribeToEvents();
    this.matIconRegistry.addSvgIcon(
      'sim',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/calculator.svg")
    );
    
    this.matIconRegistry.addSvgIcon(
      'test',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/chemical-weapon.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'home',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/home.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/brush.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'default',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/standard-definition.svg")
    );

    

    this.matIconRegistry.addSvgIcon(
      'fuel',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/fuelgreen.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/calendar.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'money',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/cash-multiple.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'sitemap',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/sitemap.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'users',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/account-multiple.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'user',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/account.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'dashboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/dashboard.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'emission',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/emission.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'certificate',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/certificate.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'electricity',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/flash.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'coefficient',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/flask-outline.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'energytax',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/energytax.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'carbondioxidetax',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/periodic-table-co2.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'report',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/card-key.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'net',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/vector-polyline.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'plant',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/factory.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/cog.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'left-chevron',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/left-chevron.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'right-chevron',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/right-chevron.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'right-double-chevron',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/chevron-double-right.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'left-double-chevron',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/chevron-double-left.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'systems',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/systems.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'alert',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/alert.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'faultreport',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/alert.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'reports',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/file-document-box-multiple-outline.svg")
    );
    
    this.matIconRegistry.addSvgIcon(
      'delivery',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/delivery.svg")
    );
    
    this.matIconRegistry.addSvgIcon(
      'company',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/currency-usd.svg")
    );
    this.matIconRegistry.addSvgIcon(
      'contact',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/cellphone-wireless.svg")
    );
    
  }

  private subscribeToEvents(): void {
    this.signalrService.connectionEstablished.subscribe(() => {
      this.isConnectedToHub = true;
    });
    this.signalrService.messageReceived.subscribe((message: ChatMessage) => {
      this._ngZone.run(() => {
        console.log(message.message);
        this.showNotification(message.message);
        });
      });
  };

  private setLanguage(settings: SettingsState) {
    const { language } = settings;
    if (language) {
      this.translate.use(language);
    }
  }

  private setTheme(settings: SettingsState) {
    const { theme, autoNightMode } = settings;
    const hours = new Date().getHours();
    const effectiveTheme = (autoNightMode && (hours >= 20 || hours <= 6)
      ? NIGHT_MODE_THEME
      : theme
    ).toLowerCase();
    this.componentCssClass = effectiveTheme;
    const classList = this.overlayContainer.getContainerElement().classList;
    const toRemove = Array.from(classList).filter((item: string) =>
      item.includes('-theme')
    );
    if (toRemove.length) {
      classList.remove(...toRemove);
    }
    classList.add(effectiveTheme);
  }

  onLanguageSelect({ value: language }) {
    this.store.dispatch(new ActionSettingsChangeLanguage({ language }));
    this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
  }

  onCompanySelect({ value: company}) {

  }

  private subscribeToSettings() {
    if (AppComponent.isIEorEdge()) {
      this.store.dispatch(
        new ActionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }
    this.store
      .select(selectorSettings)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(settings => {
        this.settings = settings;
        this.setTheme(settings);
        this.setLanguage(settings);
        this.animationService.updateRouteAnimationType(
          settings.pageAnimations,
          settings.elementsAnimations
        );
      });
  }

  private subscribeToRouterEvents() {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.titleService.setTitle(event.snapshot);
      }

      if (event instanceof NavigationEnd) {
        AppComponent.trackPageView(event);
      }
    });
  }

  openUserConfigDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    // dialogConfig.width = '600px';
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      selectedCompany: this.selectedSrv.companyRole
    };

    const dialogRef = this.dialog.open(UserConfigDialogComponent,
      dialogConfig);

      dialogRef.afterClosed().subscribe(val => {
       // console.log('Dialog output:', val),
        this.selectedSrv.setSelectedCompany(val.selectedCompany);
      });

  };

  private showNotification(message: string, action?: string) {
    return this.snackBar.open(message, action, {
      duration: 2500,
      panelClass: 'todos-notification-overlay'
    });
  }

}
