import { Guid } from 'guid-typescript';
import { HttpErrorResponse } from '@angular/common/http';
import { MMEDefaultValue, MMEIndatauppsattning } from './indata';

export class PageSettings {
  public sidenavopen:boolean;
  public sidnavwidth: number;
  public sidenavwidthopen:number;
  public sidenavwidthclosed:number;
  public initialized: boolean;
  constructor() {
    this.sidenavopen=true;
    this.sidenavwidthopen=400;
    this.sidnavwidth=this.sidenavwidthopen;
    this.sidenavwidthclosed=400;
  }

  public toogle() {
    /*
    if(this.sidnavwidth == this.sidenavwidthclosed)
      this.sidnavwidth=this.sidenavwidthopen;
    else
      this.sidnavwidth=this.sidenavwidthclosed;
    */
   this.sidenavopen=!this.sidenavopen;
  }
}

export class ObjectStatus {
  public status: string;
  prevStatus: string;

  public setNew() {
    this.status="new";
  }

  public setDeleted() {
    this.prevStatus=this.status;
    this.status="deleted";
  }

  public undoDelete() {
    this.status=this.prevStatus;
  }

  public setUpdated() {
    if(this.status!="new") {
      this.status="updated";
    }
  }
}

export class Module {
  public _id: string;
}




export class ObjectType {
  public _id: string;
  public idLevel2: number;
  public level1: string;
  public level2: string;
  public icon: string;
}

export class User {
    public _id: string;
    public isProfuAdmin: boolean;
    public isPhoneVerified: boolean;
    public firstName: string;
    public lastName: string;
    public email: string;
    public phone: string;
    public defaultValues: MMEDefaultValue[];
    public mmeIndata: MMEIndatauppsattning;
  }

  
export class CustomerCompany {
    public _id: string;
    public name: string;
    public role: Role;
    public users: User[];
  }

  
export class Role {
    public _id: string;
    public name: string;
  }


  export class CompanyRole {
    public _id: string;
    public name: string;
    public role: Role;
    public user: User;
    public customerCompany: CustomerCompany;
  
    constructor () {
      this.role = new Role();
     
    }
  }


  export class SelectedItems {
    public companyRole: CompanyRole;
  }

