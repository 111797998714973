
import { ReportcontainerComponent } from './reports/reportcontainer/reportcontainer.component';


import { GraphvisualizerComponent } from './graphvisualizer/graphvisualizer.component';

import { BookdemoComponent } from './bookdemo/bookdemo.component';

import { ContactComponent } from './contact/contact.component';
import { AdminUserComponent } from './admin-user/admin-user.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';

import { StartComponent } from './start/start.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CallbackComponent } from './callback/callback.component';

import { AuthGuard } from './auth/auth.guard';
import { SettingsComponent } from './settings';
import { MyaccountComponent } from '@app/myaccount/myaccount.component';
import { PdftestComponent } from '@app/experiments/pdftest/pdftest.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CompanyComponent } from './company/company.component';
import { RootuserconsoleComponent } from './rootuserconsole/rootuserconsole.component';
import { DefaultValueComponent } from './default-value/default-value.component';
import { IndatasComponent } from './indatas/indatas.component';
import { ResultsComponent } from './results/results.component';
import { IndataComponent } from './indata/indata.component';
import { UploadComponent } from './upload/upload.component';

export const ROUTES: Routes = [
  { path: '', component: StartComponent},
  

    { path: 'reportmgr', component: ReportcontainerComponent,
    data: {allowRoles: ['anms.role.systemowner', 'anms.role.keymanager']},
    canActivate: [AuthGuard] },

    
 
 
  { path: 'admin-user', component: AdminUserComponent, canActivate: [AuthGuard]},
  { path: 'start', component: StartComponent},
 
  { path: 'gv', component: GraphvisualizerComponent},
  { path: 'pdftest', component: PdftestComponent},
  { path: 'upload', component: UploadComponent},
  
  { path: 'callback', component: CallbackComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'company', component: CompanyComponent,canActivate: [AuthGuard] },
  { path: 'admin', component: RootuserconsoleComponent,canActivate: [AuthGuard] },
  { path: 'indatas', component: IndatasComponent,canActivate: [AuthGuard] },
  { path: 'indata', component: IndataComponent,canActivate: [AuthGuard] },
  { path: 'results', component: ResultsComponent,canActivate: [AuthGuard] },
 
  { path: 'bookdemo', component: BookdemoComponent },
  { path: 'defaultValues', component: DefaultValueComponent,canActivate: [AuthGuard] },
  { path: 'reports', component: ReportcontainerComponent,canActivate: [AuthGuard] },
  { path: 'myaccount', component: MyaccountComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
 
  {
    path: 'settings',
    component: SettingsComponent,
    data: { title: 'anms.menu.settings' }
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule],
  providers: [
    AuthGuard
  ]
})
export class AppRoutingModule { }
